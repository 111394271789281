import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app';

// react alerts
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic';

const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    transition: transitions.SCALE,
    containerStyle: {
        zIndex: 999999999
    }
}

ReactDOM.render(
    <AlertProvider template={AlertTemplate} {...options}>
        <App />
    </AlertProvider>, document.getElementById('root'));