import React, { useState, Fragment, useEffect, useRef } from 'react';
import './app.scss';
import { TiLocationOutline, TiDirections } from 'react-icons/ti';
import { MdComputer } from 'react-icons/md';
import { AiOutlineSolution, AiOutlineSetting } from 'react-icons/ai';
import { FaHandsHelping, FaResolving, FaCaretSquareLeft, FaCaretSquareRight, FaRegCopyright } from 'react-icons/fa';
import { BsFillPeopleFill } from 'react-icons/bs';
import { RiTeamLine } from 'react-icons/ri';
import { RiCompass3Line } from 'react-icons/ri';
import { IoMdLogIn, IoMdLogOut } from 'react-icons/io';
// models
import ContactModel from '../model/contact';
import AuthModel from '../model/auth';
// react alert
import { useAlert } from 'react-alert';
// svgs
import Defination from './defination';

let scrollOffcet = 0;
const BanerItems = [
    {
        title: "Agile IT Strategy",
        content: "Agile IT Strategy sets the direction for an organization’s IT efforts and identifies how to leverage technology to maximize business value and achieve the optimal ROI. Agility is a required dimension for any enterprise  to compete and thrive in this digital age by quickly responding to market changes and emerging opportunities with innovative business solutions."
    }, {
        title: "Enterprise Architecture",
        content: "Enterprise architecture is the process by which organizations standardize and organize IT infrastructure to align with business goals. These strategies support digital transformation, IT growth and the modernization of IT as a department."
    }, {
        title: "Enterprise Risk and Issue Management",
        content: "Risk and Issue management helps an enterprise to minimize and manage any internal or external risks that are encountered when managing Systems, Projects and Processes. A robust Risk and Issue management clearly defines and identifies sources of risks, explains risk management perspectives, describes all the steps involved in the framework, discusses threat and opportunity responses and finally, documents necessary steps and generates critical reporting for senior stakeholders."
    }, {
        title: "Demand Management",
        content: "Demand management internally collects new ideas, projects, and IT needs to prioritize and select a valuable strategically aligned efforts to help an Enterprise meet its current as well as future goals."
    }, {
        title: "Enterprise Services and Systems Management",
        content: "Service management, also known as information technology service management, software allows companies to manage how it provides services to its customers. Services include order management, hardware and software maintenance, diagnostics and troubleshooting, as well as routine operations."
    }, {
        title: "Enterprise Data Management",
        content: "Enterprise Data Management is the ability of an organization to precisely define, easily integrate and effectively retrieve data for both internal applications and external communication. EDM is focused on the creation of accurate, consistent and transparent content."
    }, {
        title: "Process and Skills Management",
        content: "Business success depends on the process maturity and management, technical, and collaborative skills. Documented and matured process with right blend of skills and tools are essential for managing all the alue streams of an enterprise"
    }, {
        title: "Cyber Security",
        content: "Cyber Security is the practice of protecting computers, servers, mobile devices, electronic systems, networks, and data from external intrusion and malicious attacks. It can only be achieved through a combined effort of using system tools and user training."
    }]

export default () => {
    const [activeModel, setActiveModel] = useState("")
    const [scrollTo, toggleScroll] = useState(0);
    const [intervalT, setIntervalT] = useState(6000);
    const [user, setUser] = useState(null);
    const alert = useAlert();

    // timmer scroll
    useEffect(() => {
        setInterval(() => {
            if (scrollOffcet === 0) {
                scrollOffcet = -100;
                toggleScroll(-100)
            } else if (scrollOffcet === -100) {
                scrollOffcet = -200;
                toggleScroll(-200);
            } else if (scrollOffcet === -200) {
                scrollOffcet = -300;
                toggleScroll(-300);
            } else if (scrollOffcet === -300) {
                scrollOffcet = -400;
                toggleScroll(-400);
            } else if (scrollOffcet === -400) {
                scrollOffcet = -500;
                toggleScroll(-500);
            } else if (scrollOffcet === -500) {
                scrollOffcet = -600;
                toggleScroll(-600);
            } else if (scrollOffcet === -600) {
                scrollOffcet = -700;
                toggleScroll(-700);
            } else if (scrollOffcet === -700) {
                scrollOffcet = 0;
                toggleScroll(0);
            }
        }, intervalT)
    }, []);

    // looking for user auth
    useEffect(() => {
        const usr = sessionStorage.getItem("User");
        if (usr) {
            setUser(JSON.parse(usr));
        }
    }, []);

    const _renderBaners = () => {
        return <div>
            <div>
                {/* <img src={require(`../img/def${(Math.abs(scrollOffcet) / 100) + 1}.svg`)} /> */}
                <Defination
                    setItem={(offcet) => {
                        toggleScroll(-offcet)
                        scrollOffcet = -offcet;
                    }}
                    activeItem={(Math.abs(scrollTo) / 100)} />
            </div>
            <div>
                {[...Array(8).keys()].map((item, i) => {
                    return <div key={i}>
                        <div style={{ left: `${scrollTo}%`, position: 'relative' }}>
                            <h2>{BanerItems[i].title}</h2>
                            <p>{BanerItems[i].content}</p>
                        </div>
                    </div>
                })}
            </div>
            {/* <div>
                <FaCaretSquareLeft onClick={() => {
                    setIntervalT(4000);
                    const val = scrollOffcet !== 0 ? scrollOffcet + 100 : 0
                    toggleScroll(val);
                    scrollOffcet = val;
                }} />
                <FaCaretSquareRight onClick={() => {
                    setIntervalT(4000);
                    const val = scrollOffcet !== -700 ? scrollOffcet - 100 : -700
                    toggleScroll(val);
                    scrollOffcet = val;
                }} />
            </div> */}
        </div>
    }

    const _renderZemobotron = () => {
        return <div className="zomobotron" >
            <img src={require('../img/map.png')} />
            <div>
                {/* scroll baner */}
                {_renderBaners()}
            </div>
        </div>
    }

    const _renderNavBar = () => {
        return <div className="navbar" >
            <div>
                <div>
                    <img className="logo" src={require('../img/logo.png')} />
                    <label className="title" > Techgrity </label>
                </div>
                <div>
                    < MdComputer />
                    <a href="#productsPage" > Products </a> </div>
                <div>
                    <AiOutlineSolution />
                    <a href="#solutionsPage" > Solutions </a>
                </div>
                <div>
                    <AiOutlineSetting color="white" />
                    <a href="#servicesPage" > Services </a>
                </div>
                <div>
                    <RiCompass3Line />
                    <a href="#consultingPage" > Consulting </a>
                </div>
                <div className="location_div">
                    <TiLocationOutline />
                    <p> USA | INDIA </p>
                </div>
                <div>
                    <a className="login" onClick={() => {
                        if (user) {
                            sessionStorage.removeItem("User");
                            setUser(null);
                            alert.show("Logged of successfully")
                        } else {
                            setActiveModel('ShowAuthModel');
                        }
                    }}> {user ? <IoMdLogOut /> : <IoMdLogIn />} </a>
                </div>
            </div>
        </div>
    }

    const _renderBottomView = () => {
        return <div className="bottom">
            <div>
                <h2>Technical Integrity for Every Enterprise! </h2>
                <p>Based on our experience, technical integrity is crucial for any Enterprise to be Agile and successful. Our TI model shows the key aspects of IT that help to maintain technical integrity in order to sustain growth with predictable outcomes. </p>
                <p>Client Focused and Vendor Neutral Strategies and Solutions. We believe in leveraging best of breed solutions to build stronger and stable operating platforms. We are in an age of rapid innovation, and in the technology landscape it is more profound. As technology strategists with experience in all aspects of IT including development, operations, and industry best practices, we can provide better insight that will enable enterprises to achieve their near and long term goals. Our objective is to guide you to make informed decisions, so you can lead a clear path to success. With our expertise, we can help you to gain agility and align your technology backbone to support challenging business needs. </p>
                <p> Contact us now for a free consult and begin your journey to attain {"\n"}technical integrity. </p>
                <div onClick={() => setActiveModel("ShowContactusModel")} > CONTACT US </div>
                <p>We are a SMALL Business with a BIG Vision! The four pillars of our delivery value chain are our team, advisors, partners, and clients. We understand we are part of a comprehensive ecosystem and we believe in establishing long term relationships to co-exist and prosper.</p>
                <div>
                    <div onClick={() => window.location = "#ourTeamPage"}>
                        <RiTeamLine />
                        <label> OUR TEAM </label>
                        <p>
                            We are team of technology enablers with decades of experience in software development and consulting in both government and commercial sectors supporting large and small enterprises. We have vast experience in research, innovation, and adoption of new technologies. We always believe that every effort should result in long lasting intrinsic and extrinsic value.
                        </p>
                    </div>
                    <div onClick={() => window.location = "#advisorsPage"} >
                        <TiDirections />
                        <label> ADVISORS </label>
                        <p>
                            We are backed by Industry experts who provide guidance as needed to help our clients succeed. Together, we proficiently help our clients create true value streams to satisfy their internal and external customers.
                        </p>
                    </div>
                    <div onClick={() => window.location = "#clientsPage"} >
                        <BsFillPeopleFill />
                        <label> CLIENTS </label>
                        <p>
                            We love our clients and we are loved by our clients. The proactiveness and accountability in every job we undertake keep our clients well satisfied.
                            </p>
                    </div>
                    <div onClick={() => window.location = "#clientsPage"} >
                        <FaHandsHelping />
                        <label> PARTNERS </label>
                        <p>
                            Teaming is the only way to achieve bigger results. We partner seamlessly to provide the best results to meet the overall objectives of the projects. Our partners provide additional coverage to cater to the required needs spanning across different areas of expertise.
                        </p>
                    </div>
                </div>
            </div>
            {_renderFooter()}
        </div>
    }

    const _renderProductsPage = () => {
        return <section id="productsPage" >
            Products page
        </section>
    }

    const _renderSolutionsPage = () => {
        return <section id="solutionsPage" >
            Solutions page
            </section>
    }

    const _renderServicesPage = () => {
        return <section id="servicesPage" >
            Services page </section>
    }

    const _renderConsultingPage = () => {
        return <section id="consultingPage" >
            Consulting page </section>
    }

    const _renderAdvisorPage = () => {
        return <section id="advisorsPage" >
            Advisor page </section>
    }

    const _renderOurTeamPage = () => {
        return <section id="ourTeamPage" >
            OurTeam page </section>
    }

    const _renderClientsPage = () => {
        return <section id="clientsPage" >
            Clients page </section>
    }

    const _renderFooter = () => {
        return <div className="footer" >

            <label > <FaRegCopyright /> 2017 Techgrity, LLC </label>
        </div>
    }

    const _renderModel = () => {
        switch (activeModel) {
            case "ShowContactusModel":
                return <ContactModel onClose={() => setActiveModel("")} />
            case "ShowAuthModel":
                return <AuthModel onClose={(usr) => {
                    setActiveModel("");
                    setUser(usr)
                }} />
        }
    }

    return <div className="app" >
        <section >
            {_renderNavBar()}
            {_renderZemobotron()}
            {_renderBottomView()}
            {_renderModel()}
        </section> {
            /* {_renderProductsPage()}
                    {_renderSolutionsPage()}
                    {_renderServicesPage()}
                    {_renderConsultingPage()}
                    {_renderAdvisorPage()}
                    {_renderOurTeamPage()}
                    {_renderClientsPage()} */
        }
    </div>
}