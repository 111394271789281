import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyBmSdx-DA4WRnLvL3NQWq_cs1n8LH1A0v8",
    authDomain: "techgrity-4cc87.firebaseapp.com",
    databaseURL: "https://techgrity-4cc87.firebaseio.com",
    projectId: "techgrity-4cc87",
    storageBucket: "techgrity-4cc87.appspot.com",
    messagingSenderId: "742427253151",
    appId: "1:742427253151:web:9f3b11a53f3e928dc905aa",
    measurementId: "G-WF9YE36L49"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;