import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={685.27}
      height={630.461}
      viewBox="0 0 685.27 630.461"
      {...props}
    >
      <defs>
        <filter
          id="prefix__a"
          x={53.704}
          y={143.527}
          width={207.661}
          height={201.281}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodOpacity={0.4} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__b"
          x={0}
          y={264.613}
          width={281.002}
          height={281.002}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-2" />
          <feFlood floodOpacity={0.4} />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__c"
          x={138.637}
          y={425.738}
          width={209.782}
          height={204.67}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-3" />
          <feFlood floodOpacity={0.4} />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__d"
          x={142.007}
          y={0}
          width={286.208}
          height={286.192}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-4" />
          <feFlood floodOpacity={0.412} />
          <feComposite operator="in" in2="blur-4" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__e"
          x={425.795}
          y={149.391}
          width={222.981}
          height={213.533}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-5" />
          <feFlood floodOpacity={0.4} />
          <feComposite operator="in" in2="blur-5" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__f"
          x={407.692}
          y={275.057}
          width={277.578}
          height={278.277}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-6" />
          <feFlood floodOpacity={0.4} />
          <feComposite operator="in" in2="blur-6" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__g"
          x={337.177}
          y={424.594}
          width={205.924}
          height={205.867}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-7" />
          <feFlood floodOpacity={0.4} />
          <feComposite operator="in" in2="blur-7" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__h"
          x={269.142}
          y={2.322}
          width={288.622}
          height={288.85}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-8" />
          <feFlood floodOpacity={0.4} />
          <feComposite operator="in" in2="blur-8" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="prefix__i"
          x={234.628}
          y={236.541}
          width={220}
          height={214}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur-9" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="blur-9" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 34">
        <g data-name="Group 12" transform="translate(6.34 9.658)">
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__a)">
            <path
              data-name="Path 10"
              d="M62.708 332.807s3.858-53.9 23.72-99.722 56.726-83.559 56.726-83.559l109.215 107.677s-15.761 18.879-23.618 37.78a109.828 109.828 0 00-7.811 37.823z"
              fill={props.activeItem === 6 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(600)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__b)">
            <path
              data-name="Path 11"
              d="M139.681 532.06s-37.194-40.08-56.485-86.13-20.68-98.07-20.68-98.07l155.565 1.08s2.59 24.18 10.786 42.946a112.668 112.668 0 0021.994 32.113z"
              fill={props.activeItem === 5 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(500)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__c)">
            <path
              data-name="Path 12"
              d="M339.453 614.64s-55.515-1.695-103.054-20.08-87.103-53.456-87.103-53.456l108.61-108.315s19.738 14.94 39.341 22.197a118.973 118.973 0 0039.071 6.832z"
              fill={props.activeItem === 4 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(400)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__d)">
            <path
              data-name="Path 13"
              d="M150.9 137.102s42.913-36.167 90.632-53.801 103.242-17.723 103.242-17.723l-4.005 151.126s-26.912 1.927-46.363 9.437-34.437 21.59-34.437 21.59z"
              fill={props.activeItem === 7 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(700)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__e)">
            <path
              data-name="Path 14"
              d="M627.456 351.422s1.259-56.152-15.302-103.323-54.962-85.024-54.962-85.024l-117.27 100.79s18.954 17.013 25.45 36.45 7.877 39.853 7.877 39.853z"
              fill={props.activeItem === 1 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(100)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__f)">
            <path
              data-name="Path 15"
              d="M542.597 536.765s36.558-35.02 60.536-79.535 24.032-92.083 24.032-92.083l-154.082-14.505s-.533 22.022-10.639 40.133-25.289 30.322-25.289 30.322z"
              fill={props.activeItem === 2 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(200)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__g)">
            <path
              data-name="Path 16"
              d="M351.027 615.336s50.568-.872 98.107-19.256 83.171-47.197 83.171-47.197L430.337 432.171s-22.45 17.08-42.048 24.337a118.974 118.974 0 01-39.076 6.833z"
              fill={props.activeItem === 3 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(300)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__h)">
            <path
              data-name="Path 17"
              d="M548.76 151.93s-40.138-39.144-87.355-60.96S356.89 64.664 356.89 64.664l-4.17 153.738s26.15 4.011 45.371 13.23a119.618 119.618 0 0132.527 23.65z"
              fill={props.activeItem === 0 ? "#0f8" : "#c3fdf8"}
              onClick={() => {
                props.setItem(0)
              }}
            />
          </g>
          <g transform="translate(-6.34 -9.66)" filter="url(#prefix__i)">
            <ellipse
              data-name="Ellipse 5"
              cx={101}
              cy={98}
              rx={101}
              ry={98}
              transform="translate(243.63 242.54)"
              fill="rgba(0,235,125,0.49)"
            />
          </g>
          <ellipse
            data-name="Ellipse 6"
            cx={90}
            cy={87}
            rx={90}
            ry={87}
            transform="translate(248.288 243.883)"
            fill="#ff7272"
          />
          <path
            data-name="Union 19"
            d="M292.25 452.177l7.877-18.493-6.62-2.666 12.823-4.225 5.99 11.796-6.545-2.635-7.877 18.493z"
            fill="#65cff6"
          />
          <path
            data-name="Union 20"
            d="M394.046 429.345l-6.431 2.589 7.69 18.568-5.618 2.328-7.716-18.628-6.734 2.71 5.985-11.795z"
            fill="#65cff6"
          />
          <path
            data-name="Union 21"
            d="M402.44 220.873l-9.242 21.684 6.618 2.664-13.403 5.607-5.401-13.177 6.545 2.635 9.241-21.684z"
            fill="#65cff6"
          />
          <path
            data-name="Union 22"
            d="M295.084 242.128l6.385-2.57-9.55-23.055 5.62-2.327 9.573 23.11 6.78-2.728-5.167 13.73z"
            fill="#65cff6"
          />
          <path
            data-name="Union 23"
            d="M219.948 381.319l20.341-8.19-2.745-6.434 12.707 5.592-4.919 12.689-2.71-6.361-20.341 8.19z"
            fill="#65cff6"
          />
          <path
            data-name="Union 24"
            d="M446.155 366.396l-2.66 6.24 19.603 8.12-2.282 5.507-19.658-8.143-2.794 6.556-4.632-12.573z"
            fill="#65cff6"
          />
          <path
            data-name="Union 25"
            d="M465.75 289.756l-19.685 7.928 2.743 6.436-12.422-5.711 4.631-12.573 2.71 6.361 19.686-7.926z"
            fill="#65cff6"
          />
          <path
            data-name="Union 26"
            d="M236.358 311.08l2.649-6.215-22.217-9.202 2.281-5.508 22.272 9.226 2.805-6.582 5.768 13.03z"
            fill="#65cff6"
          />
          <text
            data-name="Demand Management"
            transform="translate(416.288 507.883)"
            fill="black"
            fontSize={19}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-40.208} y={0}>
              {"Demand "}
            </tspan>
            <tspan x={-59.37} y={26}>
              {"Management"}
            </tspan>
          </text>
          <text
            data-name="Risk &amp; Issue Management"
            transform="translate(527.288 391.883)"
            fill="black"
            fontSize={19}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-53.962} y={0}>
              {"Risk And Issue"}
            </tspan>
            <tspan x={-61.991} y={26}>
              {" Management"}
            </tspan>
          </text>
          <text
            data-name="Enterprise Architecture"
            transform="translate(530.288 255.883)"
            fill="black"
            fontSize={19}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-45.111} y={0}>
              {"Enterprise"}
            </tspan>
            <tspan x={-55.089} y={26}>
              {"Architecture"}
            </tspan>
          </text>
          <text
            data-name="Cyber Security"
            transform="translate(257.288 155.883)"
            fontSize={20}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-67.969} y={0}>
              {"Cyber Security"}
            </tspan>
          </text>
          <text
            data-name="Agile IT Strategy"
            transform="translate(433.288 155.883)"
            fill="black"
            fontSize={20}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-78.306} y={0}>
              {"Agile IT Strategy"}
            </tspan>
          </text>
          <text
            data-name="Systems And Service Management"
            transform="translate(254.288 501.883)"
            fill="#565656"
            fontSize={19}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-46.753} y={0}>
              {"Systems And"}
            </tspan>
            <tspan x={-32.174} y={26}>
              {"Service"}
            </tspan>
            <tspan x={-59.37} y={52}>
              {"Management"}
            </tspan>
          </text>
          <text
            data-name="Data Management"
            transform="translate(142.288 394.883)"
            fill="black"
            fontSize={19}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-20.925} y={0}>
              {"Data"}
            </tspan>
            <tspan x={-59.37} y={26}>
              {"Management"}
            </tspan>
          </text>
          <text
            data-name="Process &amp; Skills Management"
            transform="translate(138.288 238.883)"
            fill="black"
            fontSize={19}
            fontFamily="SegoeUI-Bold, Segoe UI"
            fontWeight={700}
          >
            <tspan x={-44.137} y={0}>
              {"Process And"}
            </tspan>
            <tspan x={-25.536} y={26}>
              {"Skills "}
            </tspan>
            <tspan x={-59.37} y={52}>
              {"Management"}
            </tspan>
          </text>
        </g>
        <text
          data-name="Technical Integrity"
          transform="translate(284.628 332.541)"
          fill="#fff"
          fontSize={29}
          fontFamily="SegoeUI-Bold, Segoe UI"
          fontWeight={700}
        >
          <tspan x={0} y={0}>
            {"Technical"}
          </tspan>
          <tspan x={0} y={38}>
            {"Integrity"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default SvgComponent