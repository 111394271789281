import React, { useState } from 'react';
import './auth.scss';
import firebase from '../services/firebase'
import ReactLoading from 'react-loading';
// react alert
import { useAlert } from 'react-alert';

export default ({ onClose }) => {
    const [{ password, email }, setForm] = useState({ password: "", email: "" });
    const [loading, toggleLoading] = useState(false);
    const [authType, setAuthType] = useState("Login");
    const alert = useAlert();

    const onTyping = (e) => {
        const nm = e.currentTarget.name;
        const value = e.currentTarget.value;
        setForm(frm => ({ ...frm, [nm]: value }));
    }

    const onClick = () => {
        if(email.trim().length > 0 && password.trim().length > 0){
            toggleLoading(true);
            if (authType === "Login") {
                firebase.auth().signInWithEmailAndPassword(email, password).then((response) => {
                    toggleLoading(false);
                    alert.show("Loged in successfully");
                    sessionStorage.setItem("User", JSON.stringify({ email, password }));
                    onClose({ email, password });
                }).catch((e) => {
                    toggleLoading(false);
                    alert.show(e.message);
                })
            } else {
                firebase.auth().createUserWithEmailAndPassword(email, password).then((response) => {
                    toggleLoading(false);
                    alert.show("Registered successfully");
                    sessionStorage.setItem("User", JSON.stringify({ email, password }));
                    onClose({ email, password });
                }).catch((e) => {
                    toggleLoading(false);
                    alert.show(e.message);
                })
            }
        }else{
            alert.show("Please provide email/password");
        }
    }

    return <div className="auth">
        <div>
            {/* header */}
            <div>
                <label>{authType === "Login" ? "Login" : "Register"}</label>
                <p>Please provide your credentials</p>
            </div>
            <div>
                <input type="email" onChange={onTyping} value={email} name="email" placeholder="Email" />
                <input type="password" onChange={onTyping} value={password} name="password" placeholder="Password" />
            </div>
            <div>
                <label onClick={() => onClose(null)}>CLOSE</label>
                <label onClick={onClick} name="send">{authType === "Login" ? "LOGIN" : "REGISTER"}</label>
            </div>
            {
                authType === "Login" ?
                    <div>Don't have an account, <a onClick={() => { setAuthType("Register") }}>Register</a></div> :
                    <div>Already have an account, <a onClick={() => { setAuthType("Login") }}>Login</a></div>
            }
        </div>
        {
            loading ?
                <div className="loading">
                    <ReactLoading type={"spin"} color="black" height={'4%'} width={'4%'} />
                </div> : null
        }
    </div>
}