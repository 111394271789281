import React, { useState } from 'react';
import './contact.scss';
import firebase from '../services/firebase'
import ReactLoading from 'react-loading';

export default ({ onClose }) => {
    const [{ name, email, comments }, setForm] = useState({ name: "", email: "", comments: "" });
    const [error, toggleError] = useState("");
    const [success, toggleSuccess] = useState("");
    const [loading, toggleLoading] = useState(false);

    const onTyping = (e) => {
        const nm = e.currentTarget.name;
        const value = e.currentTarget.value;
        setForm(frm => ({ ...frm, [nm]: value }));
    }

    const onSendClick = (e) => {
        if (name.length === 0) {
            toggleError("Provide name field");
        } else if (email.length === 0 || !email.includes('@') || !email.includes('.')) {
            toggleError("Incorrect email format");
        } else if (comments.length === 0) {
            toggleError("Let us know why you wanted to contact us, type in comments field");
        } else {
            toggleLoading(true)
            firebase.firestore().collection('Contact').add({ name, email, comments, timeStamp: new Date().toLocaleString(), timeStamp: new Date().getTime() }).then((snap) => {
                toggleLoading(false);
                toggleError("")
                toggleSuccess("We recieved your details. We'll comeback to you in a moment");
                setTimeout(() => {
                    onClose();
                }, 2000);
            }).catch((e) => {
                toggleLoading(false);
                toggleError("OOPS somthing went wrong!");
            })
        }
    }

    return <div className="contact">
        <div>
            {/* error view */}
            <div style={error.length > 0 ? { backgroundColor: "rgb(196, 24, 24)" } : success.length > 0 ? { backgroundColor: "#00ace6" } : { display: 'none' }}>
                <label>{error.length > 0 ? "Error:" : ""} {error + success}</label>
                <label onClick={() => { toggleError(""); toggleSuccess("") }}>X</label>
            </div>
            {/* header */}
            <div>
                <label>Get in Touch</label>
                <p>Please provide your name, email, and short description of your request. We will respond to you soon.</p>
            </div>
            <div>
                <input onChange={onTyping} value={name} name="name" placeholder="Name" />
                <input onChange={onTyping} value={email} name="email" placeholder="Email" />
                <textarea onChange={onTyping} value={comments} name="comments" placeholder="Comments"></textarea>
            </div>
            <div>
                <label onClick={onClose}>CLOSE</label>
                <label onClick={onSendClick} name="send">SEND</label>
            </div>
        </div>
        {
            loading ?
                <div className="loading">
                    <ReactLoading type={"spin"} color="black" height={'4%'} width={'4%'} />
                </div> : null
        }
    </div>
}